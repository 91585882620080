import React from "react";
import { useEffect, useState } from "react";
import {
    pmpContract,
  connectWallet,
  getCurrentWalletConnected,
  purchase,
  totalSupply,
  maxTokens,
  mintPrice
} from "../util/interact.js";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import axios from "axios";

const Mint = () => {
  //state variables
  const [walletAddress, setWallet] = useState("");
  const [minted_count, setMintedCount] = useState("");
  const [max_tokens, setMaxTokens] = useState("");
  const [mint_price, setMintPrice] = useState(0);
  const [progress, setProgress] = useState(false);
  const [activeComponent, setActiveComponent] = useState("");

  //called only once
  useEffect(async () => {
    setProgress(false);

    const address = await getCurrentWalletConnected();
    setWallet(address);

    if(address.length > 0) {
      setActiveComponent("MINTING");
    } else {
      setActiveComponent("CONNECT_WALLET");
    }

    addWalletListener();
    
    const count = await totalSupply();
    setMintedCount(count);
    const tokens = await maxTokens();
    setMaxTokens(tokens);
    const price = await mintPrice();
    setMintPrice(price);

    addSmartContractListener(address);

  }, []);

  function addSmartContractListener(_wallet) {
    pmpContract.events.Transfer({}, (error, data) => {
      if (data.returnValues["to"].toLowerCase() == _wallet.toLowerCase() && data.returnValues["tokenId"] >= 0) {
        setProgress(false);
        setActiveComponent("RESULTS");
      }
      if (error) {
        console.log(error.message);
      } else {
        
      }
    });
  
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setActiveComponent("MINTING");
        } else {
          setWallet("");
          setActiveComponent("CONNECT_WALLET");
        }
      });
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(walletResponse);
  };

  const mintPressed = async (count) => {
    setProgress(true);

    var eth_amount = count * mint_price
    const tx_result = await purchase(walletAddress, count, eth_amount.toString(16));

    if(tx_result["code"] == 4001) {
      //There was an error, or user canceled the transaction
      setProgress(false);
    }
  };

  function WalletConnect(props) {
    return (
      <div className="mint">
        <div className="anchor"></div>
        <div className="contentContainer">
            <div className="vertical_center">
                <h1>Mint a pal!</h1>
                <Button
                    variant="outlined"
                    onClick={connectWalletPressed}
                    style={{minWidth:"140px",
                            minHeight:"40px",
                            borderRadius:"12px",
                            borderWidth:"1px",
                            textTransform:"none",
                            fontFamily:"Red Hat Display",
                            fontWeight:"500",
                            fontSize:"15pt",
                            background: '#FFF',
                            borderColor: '#000',
                            color: '#000'
                            }}
                    >Connect Wallet
                    </Button>
                    <p>Connect your Metamask wallet to mint.</p>
                </div>
            </div>
       </div> 
    );
  }

  class MintForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      const val = event.target.value;
      if (!Number(val) && val != '') {
        return;
      }
      this.setState({value: val});
    }
  
    handleSubmit(event) {
      mintPressed(this.state.value);
      event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit}>
          <div className="question">
            <input type="text" value={this.state.value} onChange={this.handleChange}/>
            <label>Quantity</label>
          </div>
          <button>Mint</button>
        </form>
      );
    }
  }

  function Minting(props) {

    return (
      <div className="mint">
        <div className="anchor"></div>
        <div className="contentContainer">
            <div className="vertical_center">
                <div className="inputContainer">
                    <div className="image_col">
                        <img src="./img/mint_left.jpg" />
                    </div>
                    <div className="inputTitle">Mint your PMP!</div>
                    <div className="inputAddress">Connected: <span className="mintAddress"> {walletAddress.slice(0, 6)}...{walletAddress.slice(
                            walletAddress.length - 4,
                            walletAddress.length
                            )}</span></div>
                    <div className="inputMinted">{minted_count} <span className="mintedText"> minted thus far</span></div>
                    <div className="inputPrice">price: {mint_price / 10**18} <span className="inputCurrency"> ETH</span></div>
                    <div className="inputQuantity"><MintForm /></div>
                </div>
            </div>
        </div>
      </div>
    );
  }

  function Results(props) {
    return (
        <div className="mint">
        <div className="anchor"></div>
        <div className="contentContainer">
            <div className="vertical_center">
                <div className="inputContainer">
                    <div className="image_col">
                        <img src="./img/mint_complete.jpg" />
                    </div>
                    <div className="inputTitle">Mint Complete!</div>
                    <div className="inputAddress">Welcome to the Pizza Mafia Pals.</div>
                    <div className="results_opensea"><a href="https://testnets.opensea.io/collection/pizza-mafia-pals">View on Opensea</a></div>
                </div>
            </div>
        </div>
      </div>
    );
  }

  class Loading extends React.Component {
    render(){
      return(
        <div>
          <div className="overlay">
            <div id='outer'>
            <div id='middle'>
              <div id='inner'>   
              </div>
              </div>
              </div>
          </div>
          <div className="overlayBackground" />
        </div>
      );
    }
  }

  function SwitchComponents({ active, children }) {
    // Switch all children and return the "active" one
    return children.filter(child => child.props.name === active)
  }

  return (
    <div id="mint">
      <div className="mintContainer">
        {progress && ( <Loading /> )}
        <SwitchComponents active={activeComponent}>
          <WalletConnect name="CONNECT_WALLET" />
          <Minting name="MINTING" />
          <Results name="RESULTS" />
        </SwitchComponents>
      </div>
    </div>
  );
};

export default Mint;