import React from "react";
import { Navigation } from "./components/navigation.js";
import { About } from "./components/about.js";
import { HowShare } from "./components/howshare.js";
import { Footer } from "./components/footer.js";

import SmoothScroll from "smooth-scroll";
import Mint from "./components/mint.js";
import { Header } from "./components/header.js";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const PizzaPals = () => {

    return (
        <div>
            <Navigation />
            <Header />
            <Mint />
            <About />
            <HowShare />
            <Footer />
        </div>
    );
};

export default PizzaPals;