export const Header = () => {
    return (
        <div id='page-top'>
        <div className="anchor"></div>
        <div className='intro'>
              <div className="intro_left">
                <br /><br /><h1>Deriv Frens</h1>
                <p>For anyone who missed out on being an OG pizza fren like us, but is still passionate about RarePizzas and our culture.</p>
                <p>We built over 120 traits inspired by the OG Toppings, Mafia, and Dread Roberts personalities of the PizzaDAO!</p>
              </div>
              <div className="intro_right">
              </div>
            </div>
          </div>
    )
  }