export const HowShare = () => {
    return (
      <div className="about" id="howshare">
        <div className="anchor"></div>
        <div className="aboutGridContainer">
          <div className="aboutDescriptionContainer">
            <div className="aboutDescription">
              <div className="population">
                <div className="aboutBigNumber">How Share?</div>
              </div>
              <div>
                <br></br>
                <p><b>3/8</b>ths of the profit goes back to the DAO.</p>
                <p><b>2/8</b>ths go to our awesome Devs to build and deploy</p>
                <p><b>3/8</b>ths will go to our college education</p>
                <p><b></b>(yes, we're seniors in high school)</p>
              </div>
            </div>
          </div>
          <div className="share-banner">
            <img src="./img/how-share.jpg" alt="how share"/>
          </div>
        </div>
      </div>
    );
  };
  