import PizzaPals from './PizzaPals'
import './App.css';

function App() {
  return (
    <PizzaPals></PizzaPals>
  );
}

export default App;
