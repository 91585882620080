const alchemyKey = "wss://eth-rinkeby.alchemyapi.io/v2/4Dcpu644Kre8MJZ9wNeXpQXlvGi0AlDy";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../contract-abi.json");
const contractAddress = "0x4D5Cb72C3e6633f77293520C3ef7DeEC015eB8b3";

export const pmpContract = new web3.eth.Contract(
  contractABI,
  contractAddress
);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      return addressArray[0];

    } catch (err) {
      return "";
    }
  } else {
    return "";
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return addressArray[0];
        
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  } else {
    return "";
  }
};

export const mintPrice = async () => {
  const price = await pmpContract.methods.priceInWei().call();
  return price;
}

export const totalSupply = async () => {
  const total = await pmpContract.methods.totalSupply().call();
  return total;
}

export const maxTokens = async () => {
  const total = await pmpContract.methods.MAX_SUPPLY().call();
  return total;
}

export const onWhitelist = async (account, proof) => {
  try {
    const result = await pmpContract.methods.canMintPresale(account, proof).call({from:account});
  } catch {
    return false;
  }

  return true;
}

export const purchase = async (address, count, eth_amount) => {
  console.log("Purchasing an PizzaMafiaPal for: %s", address);

  const transactionParameters = {
    to: contractAddress,
    from: address,
    value: eth_amount,
    data: pmpContract.methods.publicMint(count).encodeABI(),
  };
  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return txHash;
  } catch (error) {
    return error;
  }
};


export const free_mint = async (address, count, proof, eth_amount) => {
  console.log("Free mint a PizzaPal for: %s", address);

  const transactionParameters = {
    to: contractAddress,
    from: address,
    value: eth_amount,
    data: pmpContract.methods.whitelistMint(count, proof).encodeABI(),
  };
  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return txHash;
  } catch (error) {
    return error;
  }
};
