export const About = () => {
    return (
      <div className="about" id="about">
        <div className="anchor"></div>
        <div className="aboutGridContainer">
          <div className="aboutDescriptionContainer">
            <div className="aboutDescription">
              <div className="population">
                <div className="aboutBigNumber">About Us</div>
                <div className="aboutStrong">We are two teenage topping artists known as:</div>
              </div>
              <div className="aboutCommunity">
                <img src="../img/box.png"></img><span className="aboutStrong"> &</span>
                <img src="../img/pepper.png"></img>
              </div>
              <div>
                <p>We love thje DAO! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                <br/>        
              </div>
              <h3>A <a href="htttp://rarepizzas.com">PizzaDAO</a> Partnership</h3>
              <div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  